// eslint-disable-next-line import/extensions
import ToggleButton from 'vue-js-toggle-button/src/Button'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import {
  uniqBy,
  throttle,
  cloneDeep
} from 'lodash'
import {
  DeleteIntentionCategory,
  DeleteIntention,
  IntentionsDrawer,
  CategoryIntentionDrawer,
  Loading,
  SvgLoader,
  CrudButtons,
  EmptyView,
  SelectMenu,
  CrudExpressions,
  Resources
} from '@/components'

export default {
  data () {
    return {
      managerDeleteIntention: false,
      managerDeleteCategory: false,
      isEditingIntention: false,
      showVinculateEntityMenu: false,
      showIntentionsDrawer: false,
      disableSelectMenu: false,
      isLoadingListSelectMenu: false,
      isLoading: false,
      fixedBar: false,
      vinculateEntityMenuPositionTop: 0,
      vinculateEntityMenuPositionLeft: 0,
      requisitionAttempts: 0,
      intentionTitle: '',
      intentionTitleToRollback: '',
      idCategorySelected: ''
    }
  },
  watch: {
    getterSelectedIntention () {
      this.isEditingIntention = false
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedIntention',
      'getterSelectedBot',
      'getterCategoriesIntention',
      'getterSelectedCategoryIntention',
      'getterCategoriesIntentionSpecial',
      'getterIntentions'
    ]),
    listOfCategories () {
      const data = []

      data.push(
        this.getterSelectedCategoryIntention,
        ...this.getterCategoriesIntentionSpecial
      )

      return uniqBy(data, 'idCategory')
    }
  },
  components: {
    SelectMenu,
    CrudButtons,
    SvgLoader,
    DeleteIntention,
    DeleteIntentionCategory,
    CategoryIntentionDrawer,
    IntentionsDrawer,
    Loading,
    Resources,
    CrudExpressions,
    EmptyView,
    ToggleButton
  },
  mounted () {
    this.actionGetAllCategoriesResource({ idBot: this.getterSelectedBot.idBot, page: 1, size: 5000 })

    this.searchStringIntentions('')

    this.$eventBus.$on('SocketNotification-rename_intent', (notification) => {
      this.notificationEvent(notification)
    })

    document.addEventListener('click', () => {
      this.throttleHandler()
    })
  },
  beforeDestroy () {
    document.removeEventListener('click', this.throttleHandler)

    this.SET_SELECTED_INTENTION()
    this.CLEAR_CATEGORIES_INTENTION_STORE()
    this.CLEAR_RESOURCES_LESS_ALL()

    this.$eventBus.$off('SocketNotification-rename_intent')
  },
  methods: {
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapActions([
      'actionEditIntention',
      'actionGetAllCategoriesResource',
      'actionDisableDisambiguationScoreIntention',
      'actionToggleDisabiguity',
      'actionGetAllCategoriesIntentionSpecial',
      'actionChangeIntentionOfCategory'
    ]),
    ...mapMutations([
      'SET_SELECTED_INTENTION',
      'SET_SELECTED_CATEGORY_INTENTION_BY_ID',
      'EDIT_INTENTION',
      'TOGGLE_PROCESSING_INTENTION',
      'CLEAR_CATEGORIES_INTENTION_STORE',
      'CLEAR_RESOURCES_LESS_ALL'
    ]),
    /* eslint-disable */
    throttleHandler: throttle(function () {
      this.$eventBus.$emit('EventListenerClickGeneric')
    }, 1000),
    /* eslint-enable */
    notificationEvent (notification) {
      if (notification.operation === 'rename_intent') {
        if (notification.isError) {
          this.addToast({
            text: this.$t('intentions.toast.errorProcessingEdition'),
            type: 'danger'
          })
          this.intentionTitle = cloneDeep(this.intentionTitleToRollback)

          this.EDIT_INTENTION({
            ...this.getterSelectedIntention,
            name: this.intentionTitle
          })
        } else {
          this.addToast({
            text: this.$t('intentions.toast.successfullyEdited'),
            type: 'success'
          })

          let intention = {}

          if (!this.disableSelectMenu) {
            intention = {
              ...this.getterSelectedIntention,
              name: this.intentionTitle || this.getterSelectedIntention.name
            }
          } else {
            intention = {
              ...this.getterSelectedIntention,
              idCategory: this.idCategorySelected
            }
          }

          this.EDIT_INTENTION(intention)
        }

        if (!this.disableSelectMenu) {
          this.requisitionAttempts = 0
        } else {
          this.disableSelectMenu = false

          this.SET_SELECTED_CATEGORY_INTENTION_BY_ID(this.idCategorySelected)
        }

        const intentMenuToDisabled = document.getElementsByClassName('container-list-intentions')[0]
        const categoryIntentMenuToDisabled = document.getElementsByClassName('container-list-categories-intentions')[0]

        if (intentMenuToDisabled) {
          intentMenuToDisabled.classList.remove('disabled')
        }
        if (categoryIntentMenuToDisabled) {
          categoryIntentMenuToDisabled.classList.remove('disabled')
        }

        this.isLoading = this.isEditingIntention = false
        this.TOGGLE_PROCESSING_INTENTION({ idIntent: notification.idArtifact, isTimeOut: false })
        this.intentionTitleToRollback = ''
      }
    },
    searchStringIntentions (search) {
      this.isLoadingListSelectMenu = true

      const data = {
        bot: this.getterSelectedBot,
        page: 1,
        size: 20,
        searchString: search || ''
      }

      this.actionGetAllCategoriesIntentionSpecial(data)
        .then(() => {
          this.isLoadingListSelectMenu = false
        })
        .catch(() => {
          this.isLoadingListSelectMenu = false
        })
    },
    changeCategoryIntention (idCategory) {
      if (idCategory !== this.getterSelectedCategoryIntention.idCategory) {
        this.disableSelectMenu = true
        this.idCategorySelected = idCategory

        const intention = {
          ...this.getterSelectedIntention,
          idCategory
        }

        this.actionChangeIntentionOfCategory(intention)
          .then(() => {
            this.disableSelectMenu = false

            this.SET_SELECTED_CATEGORY_INTENTION_BY_ID(this.idCategorySelected)
          })
          .catch((e) => {
            if (e.response.status === 409) {
              this.addToast({
                text: this.$t('intentions.toast.conflictsBetweenRecord'),
                type: 'warning'
              })
            } else {
              this.addToast({
                text: this.$t('intentions.toast.errorEditingIntent'),
                type: 'danger'
              })
            }

            this.disableSelectMenu = false
          })
      }
    },
    enableToEdit () {
      const element = document.querySelector('.intentionTitle')

      setTimeout(() => {
        element.click()
      }, 250)

      this.intentionTitleToRollback = this.intentionTitle = cloneDeep(this.getterSelectedIntention.name)
      this.isEditingIntention = true
    },
    editIntention (e) {
      if (e.keyCode === 27) {
        this.setToDefault()
      }

      if ((e.keyCode === 13 || e.button === 0) && this.intentionTitle !== '') {
        if (this.getterIntentions.find(intention => intention.name.toLowerCase() === this.intentionTitle.toLowerCase())) {
          this.addToast({
            text: this.$t('intentions.toast.intentNameUse'),
            type: 'warning'
          })
          return
        }
        const intentMenuToDisabled = document.getElementsByClassName('container-list-intentions')[0]
        const categoryIntentMenuToDisabled = document.getElementsByClassName('container-list-categories-intentions')[0]

        if (intentMenuToDisabled) {
          intentMenuToDisabled.classList.add('disabled')
        }
        if (categoryIntentMenuToDisabled) {
          categoryIntentMenuToDisabled.classList.add('disabled')
        }

        this.requisitionAttempts += 1
        this.isLoading = true

        if (this.requisitionAttempts === 1) {
          this.intentionTitle = this.intentionTitle.trim()

          const intention = {
            ...this.getterSelectedIntention,
            name: this.intentionTitle
          }

          this.actionEditIntention(intention)
            .catch((e) => {
              if (e.response.status === 409) {
                this.addToast({
                  text: this.$t('intentions.toast.conflictsBetweenRecord'),
                  type: 'warning'
                })
              } else {
                this.addToast({
                  text: this.$t('intentions.toast.errorEditingIntent'),
                  type: 'danger'
                })
              }

              if (intentMenuToDisabled) {
                intentMenuToDisabled.classList.remove('disabled')
              }
              if (categoryIntentMenuToDisabled) {
                categoryIntentMenuToDisabled.classList.remove('disabled')
              }

              this.requisitionAttempts = 0
              this.isLoading = this.isEditingIntention = false
            })
        }
      }
    },
    toogleChannelState (newState) {
      const intention = {
        ...this.getterSelectedIntention,
        disambiguationEnabled: newState
      }

      this.actionToggleDisabiguity(intention)
        .then(() => {
          this.addToast({
            text: this.$t('intentions.toast.disambiguationSuccessfully'),
            type: 'success'
          })
        })
        .catch(() => {
          this.addToast({
            text: this.$t('intentions.toast.errorDisambiguation'),
            type: 'danger'
          })
        })
    },
    setToDefault () {
      this.isEditingIntention = false
      this.showVinculateEntityMenu = false
    }
  }
}
